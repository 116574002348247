import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IFactoryResponse } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import useAxios from '@/services/api/useAxios'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'

const resource = 'employeeOnboardings'

const createEmployeeOnboarding = (axios: IFactoryFunctionRequest<any, any>) => (boardingProcessId, processId, { sendInvitation = false }): IFactoryResponse<any, any> => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/create/${boardingProcessId}/${processId}`,
    query: { sendInvitation },
  })
}

export default (axios: AxiosInstance) => ({
  createEmployeeOnboarding: createEmployeeOnboarding(useAxios(axios)),
})

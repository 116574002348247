import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IResource } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import useAxios from '@/services/api/useAxios'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'

const resource = 'comments'

const sendNotifications = (axios: IFactoryFunctionRequest) => (notifications: Array<{ url: string; comment: string; user: IResource }>) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/notifications`,
  })
}

export default (axios: AxiosInstance) => ({
  sendNotifications: sendNotifications(useAxios(axios)),
})

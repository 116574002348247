import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IProject, paginate } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'

const resource = 'engagements'

const showFirmPaginated = (axios: IFactoryFunctionRequest<any, any>) => (query: any) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2`,
    query,
  })
}
const showAdvancement = (axios: IFactoryFunctionRequest<any, any>) => ({
  page,
  limit,
  searchString,
  currentScope,
}: { page: number; limit: number; searchString: string; firmId: string; currentScope?: string | boolean }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/advancement`,
    query: {
      display: searchString,
      page,
      limit,
      isArchived: currentScope,
    },
  })
}
const showProjectTimesheet = (axios: IFactoryFunctionRequest<any, any>) => ({
  page,
  limit,
  display,
  projectIds = [],
  currentStatus,
}: { page: number; limit: number; display: string;projectIds: string[]; currentStatus?: number }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/picker`,
    query: {
      display,
      page,
      limit,
      projectIds,
      currentStatus,
    },
  })
}
const getEvaluationProjectCreate = (axios: IFactoryFunctionRequest<paginate<any>, any>) => (query: { page: number; limit: number; display: string;projectIds: string[]; currentStatus?: number }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/evaluation/create`,
    query,
  })
}
const archiveProjects = (axios: IFactoryFunctionRequest<paginate<any>, any>) => (projectIds: string[]) => {
  return axios({
    method: EMethod.patch,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/archive`,
    data: { projectIds },
  })
}

const getClientProjects = (axios: IFactoryFunctionRequest<any, any>) => ({
  page,
  limit,
  display,
  clientIds = [],
  currentStatus,
}: { page: number; limit: number; display: string;clientIds: string[]; currentStatus?: number }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/clientDetails`,
    query: {
      display,
      page,
      limit,
      clientIds,
      currentStatus,
    },
  })
}
const getProjectInvoice = (axios: IFactoryFunctionRequest<any, any>) => ({ _id }: { _id: string }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/invoicing/${_id}`,
  })
}
const getOne = (axios: IFactoryFunctionRequest<any, any>) => ({ _id }: { _id: string }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/${_id}`,
  })
}

const getProjectInformation = (axios: IFactoryFunctionRequest<any, any>) => ({ _id }: { _id: string }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/information/${_id}`,
  })
}

const checkIfProjectExist = (axios: IFactoryFunctionRequest<any, { project: undefined | { _id: string } }>) => ({ projectCode }: { projectCode?: string }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/checkIfProjectExist`,
    query: { projectCode },
  })
}
const getProjectTimesheet = (axios: IFactoryFunctionRequest<any, any>) => ({ _id }: { _id: string }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${_id}/timesheet`,
  })
}
const getOneStaffing = (axios: IFactoryFunctionRequest<any, any>) => ({ _id }: { _id: string }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/showStaffing/${_id}`,
  })
}

type TProjectBudget = Pick<IProject, 'endDate' | 'startDate' | 'display' | 'clientId' | 'isLocked' | 'rebate' | 'leadPrice' | 'subcontractor' > & {
  clientId: {
    _id: string
    display: string
  }
  staffingSetup: IProject['staffingSetup'] & {
    positionId: {
      _id: string
      display: string
    }
  }[]
  contributors: {
    contributorId: {
      _id: string
      display: string
      firstname: string
      lastname: string
    }
    percentage: number
  }[]
  conditionalFees: any[]
  invoicingInformation: {
    _id: string
    display: string
    currency: string
  }
}
const getProjectBudget = (axios: IFactoryFunctionRequest<TProjectBudget, { _id: string }>) => ({ _id }: { _id: string }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/${_id}/budget`,
  })
}

const getInterval = (axios: IFactoryFunctionRequest) => ({
  startDate,
  endDate,
  resources,
}: { startDate: Date; endDate: Date; resources: string[] }) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/interval/${startDate}/${endDate}`,
    data: resources,
  })
}

const updateOneStaffing = (axios: IFactoryFunctionRequest<any, any>) => ({
  projectId,
  setupId,
  listId,
  staffingPeriod,
}: { projectId: string; setupId: string; listId: string; staffingPeriod: any[] }) => {
  return axios({
    method: EMethod.put,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/project/${projectId}/setup/${setupId}/list/${listId}`,
    data: { staffingPeriod },
  })
}

const updateProjectBasic = (axios: IFactoryFunctionRequest<any, any>) => ({
  projectId,
  data,
}: { projectId: string; data: any }) => {
  return axios({
    method: EMethod.put,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/updateBasic/${projectId}`,
    data,
  })
}

const updateResourceStaffingList = (axios: IFactoryFunctionRequest<any, any>) => ({
  projectId,
  setupId,
  listId,
  resourceList,
}: { projectId: string; setupId: string; listId: string; resourceList: { status: number; resourceId: string | any }[] }) => {
  return axios({
    method: EMethod.put,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/project/${projectId}/setup/${setupId}/list/${listId}/resourcesList`,
    data: { resourceList },
  })
}

const updateResourceStaffingPosition = (axios: IFactoryFunctionRequest<any, any>) => ({
  projectId,
  setupId,
  positionId,
}: { projectId: string; setupId: string; positionId: string }) => {
  return axios({
    method: EMethod.put,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/project/${projectId}/setup/${setupId}/position/${positionId}`,
  })
}

const deleteResourceStaffingList = (axios: IFactoryFunctionRequest<any, any>) => ({
  projectId,
  setupId,
  listId,
}: { projectId: string; setupId: string; listId: string }) => {
  return axios({
    method: EMethod.delete,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/project/${projectId}/setup/${setupId}/list/${listId}/deleteList`,
  })
}
const createProject = (axios: IFactoryFunctionRequest<any, any>) => (data: any) => {
  return axios({
    method: EMethod.post,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/createProject`,
    data,
  })
}

const updateOrCreateSetup = (axios: IFactoryFunctionRequest<any, any>) => ({
  projectId,
  positionId,
  setup,
}: { projectId: string; positionId: string; setup: any }) => {
  return axios({
    method: EMethod.put,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/setup/updateOrCreate/${projectId}/${positionId}`,
    data: setup,
  })
}

const getResourceStaffingBetweenDates = (axios: IFactoryFunctionRequest<any, any>) => ({
  resourceId,
  start,
  end,
}: { resourceId: string; start: Date; end: Date }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/resource/${resourceId}/staffing/between/${start}/${end}`,
  })
}

const showEngagementsList = (axios: IFactoryFunctionRequest<TShowInvoiceEngagement, undefined>) => (query: { limit: number; page: number }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/invoicing`,
    query,
  })
}

const getAvailableTimesheetBooking = (axios: IFactoryFunctionRequest<any, any>) => ({
  year,
  month,
  resourceId,
  excludeProjectIds = [],
  immediate = true,
}: { resourceId: string; year: number; month: number; excludeProjectIds: string[]; immediate: boolean }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/availableTimesheetBooking/${year}/${month}/${resourceId}`,
    query: { excludeProjectIds },
    immediate,
  })
}

const filter = (axios: IFactoryFunctionRequest<any, any>) => ({
  page,
  search = '',
  startDate,
  endDate,
  limit,
}:
{
  page: number
  search?: string
  startDate?: Date
  endDate?: Date
  limit: number
},
) => {
  const query = { page, search, limit }
  if (startDate)
    query.startDate = startDate.getTime()
  if (endDate)
    query.endDate = endDate.getTime()
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/filter`,
    query,
  })
}
export default (axios: AxiosInstance) => ({
  showFirmPaginated: showFirmPaginated(useAxios(axios)),
  updateOneStaffing: updateOneStaffing(useAxios(axios)),
  updateOrCreateSetup: updateOrCreateSetup(useAxios(axios)),
  updateResourceStaffingList: updateResourceStaffingList(useAxios(axios)),
  updateResourceStaffingPosition: updateResourceStaffingPosition(useAxios(axios)),
  deleteResourceStaffingList: deleteResourceStaffingList(useAxios(axios)),
  updateProjectBasic: updateProjectBasic(useAxios(axios)),
  getResourceStaffingBetweenDates: getResourceStaffingBetweenDates(useAxios(axios)),
  filter: filter(useAxios(axios)),
  getProjectInvoice: getProjectInvoice(useAxios(axios)),
  getOne: getOne(useAxios(axios)),
  getOneStaffing: getOneStaffing(useAxios(axios)),
  getInterval: getInterval(useAxios(axios)),
  getProjectTimesheet: getProjectTimesheet(useAxios(axios)),
  getProjectBudget: getProjectBudget(useAxios(axios)),
  createProject: createProject(useAxios(axios)),
  showProjectTimesheet: showProjectTimesheet(useAxios(axios)),
  getAvailableTimesheetBooking: getAvailableTimesheetBooking(useAxios(axios)),
  checkIfProjectExist: checkIfProjectExist(useAxios(axios)),
  getClientProjects: getClientProjects(useAxios(axios)),
  showEngagementsList: showEngagementsList(useAxios(axios)),
  getEvaluationProjectCreate: getEvaluationProjectCreate(useAxios(axios)),
  showAdvancement: showAdvancement(useAxios(axios)),
  archiveProjects: archiveProjects(useAxios(axios)),
  getProjectInformation: getProjectInformation(useAxios(axios)),
})
